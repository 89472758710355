input[type="file"] {
  display: none;
}
.customFileUpload {
  border: 1px solid #2585ca;
  background-color: #2585ca;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  flex: 0.25;
  border-radius: 8px;
}


.imageContainer{
  width: 100px;
  height: 100px;
  border: 1px solid #2585ca;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.docContainer{
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
}

.image{
  height: 80px;
  border-radius: 8px;
  width: 80px;
}

.doc{
  padding-top: 10%;
  height: 100%;
  width: 100%;
}

.label {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
}

.docText {
  width: 150px;
}

.mousePointer{
  cursor: pointer;
}