$primary: #772396;
$secondary: #563c03;
$dark: #121212;
$info: #333333;
$light: #e2e2e2;
$grey: #797979;
$dark-grey: #a9a9a9;
$blue: #1171b6;
$dark-blue: #2585ca;
$light-grey: #c6c6c6;
$danger: #ca2525;
$green: #11b653;
$yellow: #b69b11;
$border-grey: #e1e1e1;
$white: #ffffff;
$red-accent: #efbebe;
$background-blue-accent: #e8edf3;
$blue-accent: #bedaef;
$yellow-accent: #efe7be;
$green-accent: #b2e9da;
$table-blue: #eff6ff;
